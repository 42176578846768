import { HOST } from "environment";


export class ApiError extends Error {
  constructor(body, response, action, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    const message = body?.message;
    super(message, ...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.name = "ApiError";
    this.response = response;
    this.body = body;
    this.action = action;
  }
  notAuthorized = () =>
    !this.action?.publicRoute && this.response?.status === 401;
}

export const logApiError = error => {
  if (process.env.NODE_ENV === "development") console.error(error);
};

export const handleErrors = async (response, action) => {
  if (!response.ok) {
    const body = await response.json();
    throw new ApiError(body, response, action);
  }
  return response;
};

export const handleResponse = async response => {
  const body = await response.json();
  if (Object.prototype.hasOwnProperty.call(body, "success") && !body.success) {
    throw new ApiError(body, response);
  }
  return body;
};

export const get = async (route, authToken) => {
  //try {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  };
  // next({ type: Types.API_REQUEST, route, requestOptions });
  const payload = await fetch(`${HOST}/${route}`, requestOptions)
    .then(error => handleErrors(error, { type: "API_ERROR" }))
    .then(handleResponse);
  // next({ type: Types.API_RESPONSE, route, method, payload });
  return payload;
  /*
  } catch (error) {
    //logApiError(error);
    // next({ type: Types.API_ERROR, route, method, error });
    throw error;
  }
  */
};
