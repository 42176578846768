import React from "react";
import FillOutComplianceForms from "./fill-out-compliance-form";
import { AuthorizePatient } from "components/new-email-order/authorize-patient";
import selectors from "selectors";
import { useFetch } from "hooks";
import { useSelector } from "react-redux";
import { Spinner } from "components/ui";
import { get } from "utils/api";

const ComplianceForm = () => {
  const authToken = useSelector(selectors.getAuthenticationToken);

  const checkTokenApi = useFetch({
    apiFn: token => get("patient_portal/mobile/token_check", token),
    defaultValue: { success: false }
  });

  React.useEffect(() => {
    if (authToken) {
      checkTokenApi.fetchData(authToken);
    }
  }, [authToken]);

  if (checkTokenApi.isFetching) return <Spinner />;
  else if (checkTokenApi.response?.success === true) {
    return <FillOutComplianceForms authToken={authToken} />;
  } else {
    return (
      <AuthorizePatient>
        <FillOutComplianceForms />
      </AuthorizePatient>
    );
  }
};

export default ComplianceForm;
