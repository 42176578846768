import { HOST } from "environment";
import browserHistory from "browser-history";
import { prop } from "ramda";
import { Types } from "../actions/api";
import fetch from "isomorphic-fetch";
import { NotificationManager } from "react-notifications";
import { handleErrors, logApiError, handleResponse } from "utils/api";

export const getRequestOptions = (
  method,
  payload,
  auth,
  publicRoute,
  headers = {
    "Content-Type": "application/json"
  }
) => {
  if (!publicRoute && !auth) {
    throw new Error("Not Logged In");
  }
  return {
    method,
    headers: {
      Authorization: `Bearer ${prop("authentication_token", auth)}`,
      ...headers
    },
    body: payload instanceof FormData ? payload : JSON.stringify(payload)
  };
};

const apiMiddleware = store => next => async action => {
  if (action.type !== Types.API) {
    next(action);
    return;
  } else {
    const { getState, dispatch } = store;
    const { route, method, body, headers, publicRoute = false } = action;
    try {
      const { auth } = getState();
      const requestOptions = getRequestOptions(
        method,
        body,
        auth,
        publicRoute,
        headers
      );
      // next({ type: Types.API_REQUEST, route, requestOptions });
      const payload = await fetch(`${HOST}/${route}`, requestOptions)
        .then(error => handleErrors(error, action))
        .then(handleResponse);
      // next({ type: Types.API_RESPONSE, route, method, payload });
      return payload;
    } catch (error) {
      logApiError(error);
      // next({ type: Types.API_ERROR, route, method, error });
      if (error.name === "ApiError" && error.notAuthorized()) {
        dispatch({ type: Types.API_NOT_AUTHORIZED });
        NotificationManager.error("Please login", "Your Session has expired");
        browserHistory.push("/login");
      }
      throw error;
    }
  }
};

export default apiMiddleware;
